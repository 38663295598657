<template>
  <div>
    <div class="container" align="left">
      <div class="d-inline-block mt-5">
        <h4>
          <strong>{{ $t('COT_title') }}</strong>
        </h4>
        <p class="mb-4" v-show="isLang === 0">{{ $t('COT_desc1') }}</p>
        <p>{{ $t('COT_desc2') }}</p>
        <h5 class="mt-5 mb-3">
          <strong>{{ $t('COT_subtitle1') }}</strong>
        </h5>
        <p>
          {{ $t('COT_sub_desc1') }}
        </p>
        <p class="numText1">{{ $t('COT_sub_desc2') }}<br /></p>

        <p class="numText1">{{ $t('COT_sub_desc3') }}</p>
        <h5 class="mt-5 mb-3">
          <strong>{{ $t('COT_subtitle2') }}</strong>
        </h5>
        <p>{{ $t('COT_sub_desc4') }}</p>
        <p class="numText1">{{ $t('COT_sub_desc5') }}<br /></p>

        <p class="numText1">{{ $t('COT_sub_desc6') }}<br /></p>
        <p class="numText1">
          {{ $t('COT_sub_desc7') }}
        </p>
        <h5 class="mt-5 mb-3">
          <strong>{{ $t('COT_subtitle3') }}</strong>
        </h5>
        <p>{{ $t('COT_sub_desc8') }}</p>
        <p class="numText1">{{ $t('COT_sub_desc9') }}<br /></p>

        <p class="numText1">{{ $t('COT_sub_desc10') }}</p>
        <h5 class="mt-5 mb-3">
          <strong>{{ $t('COT_subtitle4') }}</strong>
        </h5>
        <p>{{ $t('COT_sub_desc11') }}</p>
        <p class="numText1">{{ $t('COT_sub_desc12') }}<br /></p>

        <p class="numText1">{{ $t('COT_sub_desc13') }}</p>
        <h5 class="mt-5 mb-3">
          <strong>{{ $t('COT_subtitle5') }}</strong>
        </h5>
        <p>
          {{ $t('COT_sub_desc14') }}
        </p>
        <p class="numText1">{{ $t('COT_sub_desc15') }}<br /></p>

        <p class="numText1">{{ $t('COT_sub_desc16') }}<br /></p>
        <p class="numText1">{{ $t('COT_sub_desc17') }}</p>
        <p class="mt-5 mb-3">
          <strong>{{ $t('COT_add_title') }}</strong>
        </p>
        <p class="numText2">{{ $t('COT_add_desc1') }}</p>

        <p class="numText2">{{ $t('COT_add_desc2') }}</p>
        <p class="numText2">{{ $t('COT_add_desc3') }}</p>
        <p class="numText2">
          {{ $t('COT_add_desc4') }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Codeofethics',
  data() {
    return {
      isLang: 0,
    };
  },
  methods: {
    refreshLocale(newVal) {
      if (newVal === 'ko') {
        this.isLang = 0;
      } else if (newVal === 'en') {
        this.isLang = 1;
      } else if (newVal === 'ja') {
        this.isLang = 2;
      } else {
        this.isLang = 0;
      }
    },
  },
  watch: {
    '$i18n.locale'(newVal, oldVal) {
      this.refreshLocale(newVal);
    },
  },
  created() {
    this.refreshLocale(this.$i18n.locale);
  },
};
</script>

<style scoped lang="scss">
p {
  word-break: keep-all;
}
.numText0 {
  margin-left: 1.5rem;
  text-indent: -1.5rem;
}
.numText1 {
  margin-left: 1rem;
  text-indent: -0.7rem;
}
.numText2 {
  margin-left: 1rem;

  text-indent: -1rem;
}
.numText3 {
  margin-left: 3rem;
  text-indent: -0.5rem;
}
</style>

<i18n>
{
  "ko": {
    "COT_title": "윤리규범",
    "COT_desc1": "시간이 흐를 수록 더욱 빛나는 가치를 간직하는 SK핀크스가 되겠습니다.",
    "COT_desc2": "SK핀크스 주식회사는 SKMS를 기업경영의 근간으로 삼아 고객, 구성원, 주주, 사업파트너 등 다양한 이해관계자에 대한 가치를 창출하여 사회·경제 발전에 핵심적인 역할을 수행하고, 나아가 인류의 행복에 공헌하는 기업경영을 실천한다. 이를 위하여 윤리규범을 제정하고 모든 경영활동에서 의사결정과 행동의 판단 기준으로 삼는다.",
    "COT_subtitle1": "1. 구성원의 기본 윤리",
    "COT_sub_desc1": "우리는 SK핀크스 주식회사의 구성원으로서 자부심을 가지고 자신의 위치에서 항상 회사를 대표하는 자세로 성실히 직무를 수행한다.",
    "COT_sub_desc2": "• 公과 私를 명확히 구분하여 공정하고 투명하게 업무를 수행한다.",
    "COT_sub_desc3": "• 구성원간에 상호 존중하며, 자발적이고 의욕적으로 일할 수 있는 조직문화를 조성한다.",
    "COT_subtitle2": "2. 주주에 대한 책임",
    "COT_sub_desc4": "주주의 가치가 창출될 수 있도록 기업의 가치를 높이고, 이를 위해 투명성을 제고하고 효율적인 경영을 한다.",
    "COT_sub_desc5": "• 끊임없는 혁신을 통한 효율적인 경영으로 기업가치를 극대화하며, 그 성과를 주주와 함께 공유한다.",
    "COT_sub_desc6": "• 이사회 중심의 투명경영을 실천하며, 주주의 정당한 요구 및 제안을 존중한다.",
    "COT_sub_desc7": "• 경영자료는 제반 법규와 기준에 맞게 작성하고, 주주의 이익을 보호하기 위하여 관련 정보를 법규에 따라 성실하게 공시한다.",
    "COT_subtitle3": "3. 사업파트너와의 관계",
    "COT_sub_desc8": "협력회사와 공동발전을 추구하며, 경쟁회사와 정정당당하게 경쟁한다.",
    "COT_sub_desc9": "• 협력회사에 대해 공정한 거래 기회를 부여하고 우월적 지위를 이용한 부당 행위를 하지 않으며, 상호 이익과 공동발전을 추구한다.",
    "COT_sub_desc10": "• 경쟁회사와는 상호존중의 정신을 바탕으로 선의의 경쟁을 한다.",
    "COT_subtitle4": "4. 고객에 대한 자세",
    "COT_sub_desc11": "고객을 지속적으로 만족시켜 고객으로부터 신뢰를 얻고 궁극적으로 고객과 더불어 발전한다.",
    "COT_sub_desc12": "• 고객의 다양한 의견을 존중하고, 회사의 경영활동에 적극 반영한다.",
    "COT_sub_desc13": "• 고객의 재산과 정보를 관련 법규와 사규에 따라 안전하게 보호 한다.",
    "COT_subtitle5": "5. 사회에 대한역할",
    "COT_sub_desc14": "경제발전에의 기여와 함께 사회적 문화적 활동을 통하여 사회에 공헌하며, 사회규범에 맞는 경영을 하도록 최선을 다한다.",
    "COT_sub_desc15": "• 사회공헌 활동에 적극 참여하여 사회 전체가 행복해질 수 있도록 노력한다.",
    "COT_sub_desc16": "• 사업을 영위하는 지역의 제반 법규를 준수하고 지역사회의 전통과 문화를 존중한다.",
    "COT_sub_desc17": "• 환경친화적 경영을 통해 사회 발전에 이바지한다.",
    "COT_add_title": "부칙",
    "COT_add_desc1": "1. 본 윤리규범은 2011년 3월 11부터 시행한다.",
    "COT_add_desc2": "2. 본 윤리규범은 2018년 2월 21일부터 개정시행한다.",
    "COT_add_desc3": "3. 본 윤리규범은 2021년 3월 29일부터 개정시행한다.",
    "COT_add_desc4": "4. 구성원들이 윤리규범을 올바르게 해석하고 실행할 수 있도록 별도의 ‘윤리규범 실천지침’을 제정하여 운영한다."
  },
  "en": {
    "COT_title": "Code of Ethics",
    "COT_desc2": "Based on SKMS, we strive to create greater value for all of our stakeholders, play an instrumental role in social and economic advancement, and contribute to the wellbeing of humanity. In this context, we established the Code of Ethics to serve as our guide in pursuing and maintaining integrity in all of our everyday business decisions and actions.",
    "COT_subtitle1": "1. Guiding Principles for Our Employees",
    "COT_sub_desc1": "We, as the proud members of SK Inc., shall faithfully perform our duties with a sense of responsibility and belonging",
    "COT_sub_desc2": "• We shall value fairness and transparency in every decision-making and action.",
    "COT_sub_desc3": "• We shall respect fellow employees and actively cooperate to create an inspiring organizational culture.",
    "COT_subtitle2": "2. Pursuit of Greater Shareholder Value",
    "COT_sub_desc4": "We shall work to ensure transparent and efficient management to create greater value for our shareholders.",
    "COT_sub_desc5": "• We shall maximize our corporate value through continued innovation and efficient management and share the outcomes with our shareholders.",
    "COT_sub_desc6": "• We shall ensure transparent management led by the Board of Directors and respect our shareholders’ reasonable requests and suggestions.",
    "COT_sub_desc7": "• We shall create all of our management-related materials in compliance with relevant laws and standards and faithfully disclose related data as mandated by law to protect our shareholders’ interests.",
    "COT_subtitle3": "3. Pursuit of Coprosperity with Business Partners",
    "COT_sub_desc8": "We shall seek to build a win-win relationship with our business partners and play fairly against our competitors.",
    "COT_sub_desc9": "• We shall offer equal opportunities for all of our business partners, refrain from abusing our superior position, and pursue mutual interests and coprosperity.",
    "COT_sub_desc10": "• We shall engage in competition in good faith with our competitors based on mutual respect.",
    "COT_subtitle4": "4. Pursuit of Superior Customer Experience",
    "COT_sub_desc11": "We shall focus on enhancing customer satisfaction to earn customers’ trust and ultimately achieve continued progress with them.",
    "COT_sub_desc12": "• We shall take various customer’s feedback into consideration and reflect them in our business activities.",
    "COT_sub_desc13": "• We shall securely protect our customers’ assets and personal information based on relevant laws and our internal regulations.",
    "COT_subtitle5": "5. Pursuit of the Wellbeing of Society",
    "COT_sub_desc14": "While making contributions to economic development, we shall remain dedicated to bringing positive change to our society by practicing management aimed at growing and thriving together with the communities we serve.",
    "COT_sub_desc15": "• We shall strive to ensure the wellbeing of the entire society by engaging in a wide range of community outreach activities.",
    "COT_sub_desc16": "• We comply with all local regulations in the areas where we operate our business and respect the traditions and culture of the local community.",
    "COT_sub_desc17": "• We shall advance social progress through eco-conscious management.",
    "COT_add_title": "Supplementary Provisions",
    "COT_add_desc1": "1. This Code of Ethics shall take effect on March 11, 2011.",
    "COT_add_desc2": "2. This Code of Ethics shall take effect on February 21, 2018.",
    "COT_add_desc3": "3. This Code of Ethics shall take effect on March 29, 2021.",
    "COT_add_desc4": "4. The Guidelines on Practicing the Code of Ethics shall be put into operation to help our employees accurately understand and comply with the Code of Ethics."
  },
  "ja": {

  }
}
</i18n>
